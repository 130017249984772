<template>
  <section class="partners" id="partners">
    <div class="partners-content">
      <h1>{{ $t('partners.title') }}</h1>
      <p>{{ $t('partners.paragraphOne') }}</p>
    </div>
    <img src="./../assets/img/partners.png" alt="Partners" />
  </section>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
  export default {
    name: 'Partners',
    setup () {

      let fadeInElements = []

      onMounted(() => {
        fadeInElements = Array.from(document.getElementsByClassName('partners'))
        document.addEventListener('scroll', handleScroll)
        handleScroll();
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', handleScroll)
      })

      const handleScroll = () => {
        for (let i = 0; i < fadeInElements.length; i++) {
          let elem = fadeInElements[i]
          if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
          }
        }
      }

      const isElemVisible = (el) => {
        let rect = el.getBoundingClientRect()
        let elemTop = rect.top + 200 // 200 = buffer
        let elemBottom = rect.bottom
        return elemTop < window.innerHeight && elemBottom >= 0
      }

    }
  }
</script>

<style lang="scss">
  @import "./../assets/scss/constants/constants";

  .partners {
    max-width: 120rem;
    margin: 0 auto 15rem auto;
    background: $primary-color-black;
    height: 37rem;

    opacity: 0;
    transition: 0.3s all ease-out;
    transform: scale(0.8);

    img {
      padding: 3rem;
      width: 50%;
      float: left;
    }

    .partners-content {
      color: #ffffff;
      float: right;
      width: 40%;
      margin: 4rem 3rem 0 2rem;

      h1 {
        font-size: 5.6rem;
        margin-top: 1rem;
      }

      p {
        font-size: 2rem;
      }
    }

  }

  @media only screen and (max-width: 760px) {
    .partners {

      height: 95rem;

      img {
        width: 95%;
        display: block;
      }

      .partners-content {
        float: none;
        padding: 0.5rem 3rem 0 3rem;
        width: 90%;

        p {
          font-size: 3rem;
        }
      }

    }
  }

  @media only screen and (max-width: 550px) {
    .partners {

      height: 65rem;

      .partners-content {
        padding: 0.5rem 3rem 0 1rem
      }

      img {
        width: 91%;
      }

    }
  }
</style>