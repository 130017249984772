<template>
  <header>
    <div class="navigation">
      <img class="main-logo" src="./../assets/img/logo.png" alt="Nuclicore">
      <nav class="main-nav">
        <ul>
          <li><a href="#product" >{{ $t('navigation.product') }}</a></li>
          <li><a href="#technology" >{{ $t('navigation.technology') }}</a></li>
          <li><a href="#partners" >{{ $t('navigation.partners') }}</a></li>
          <li><a href="#about" >{{ $t('navigation.about') }}</a></li>
          <li><a href="https://app.nuclicore.com/983e5e1d-f313-48f4-9c2d-273a9c6d5a29">{{ $t('navigation.jobs') }}</a></li>
        </ul>
      </nav>
      <nav class="lang-nav">
        <ul>
          <li class="list-border" value="en" @click="changeLocal('en')">EN</li>
          <li value="de" @click="changeLocal('de')">DE</li>
        </ul>
      </nav>
    </div>

    <section class="hero">
      <h1>
        {{ $t('hero.titleFirstPart') }}<span id="typewriter"></span>
        <br><strong>{{ $t('hero.titleSecondPart') }}</strong></h1>
      <h2>{{ $t('hero.tagline') }}</h2>
      <a href="#demo"><span>{{ $t('hero.bookDemo') }}</span></a>
    </section>
  </header>

</template>

<script>
import { onMounted, onUnmounted } from 'vue'

  export default {
    name: 'Header',

    mounted() {
      if (localStorage.getItem('lang')) {
        this.$i18n.locale = localStorage.getItem('lang');
      } else {
        let userLang = navigator.language;
        if (userLang === 'de-AT' || userLang === 'de-CH' || userLang === 'de-DE' || userLang === 'de-LI' || userLang === 'de-LU') {
          localStorage.setItem('lang', 'de');
          this.$i18n.locale = 'de';
        } else {
          localStorage.setItem('lang', 'en');
          this.$i18n.locale = 'en';
        }
      }

      this.changeTypewriter()

    },

    setup () {
      let fadeInElements = []

      onMounted(() => {
        fadeInElements = Array.from(document.getElementsByTagName('header'))
        document.addEventListener('scroll', handleScroll)
        handleScroll();
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', handleScroll)
      })

      const handleScroll = () => {
        for (let i = 0; i < fadeInElements.length; i++) {
          let elem = fadeInElements[i]
          if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
          }
        }
      }

      const isElemVisible = (el) => {
        let rect = el.getBoundingClientRect()
        let elemTop = rect.top + 200 // 200 = buffer
        let elemBottom = rect.bottom
        return elemTop < window.innerHeight && elemBottom >= 0
      }

    },
    methods: {
      changeLocal(lang) {
        this.$i18n.locale = lang;
        localStorage.setItem('lang', lang);
        this.changeTypewriter();
      },

      changeTypewriter() {
        if (localStorage.getItem('lang') === 'en') {
          new Typewriter('#typewriter', {
            strings: ['code', 'expensive projects', 'time wasted'],
            autoStart: true,
            loop: true,
          });
        } else {
          new Typewriter('#typewriter', {
            strings: ['Code', 'kostspielige Projekte', 'verschwendete Zeit'],
            autoStart: true,
            loop: true,
          });
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/scss/constants/constants";


  header {
    background: url("./../assets/img/hero.png") no-repeat right top;
    padding-bottom: 30rem;

    opacity: 0;
    transition: 1s all ease-out;
    transform: scale(1);

    .navigation {

      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      padding: 2rem 0 0;

      .main-logo {
        float: left;
        margin: 0 15rem 0 0;
        width: auto;
      }

      .main-nav {
        float: left;

        ul {
          list-style: none;
          padding: 1rem;

          li {

            float: left;

            a {
              color: $primary-color-black;
              font-size: 1.8rem;
              text-decoration: none;
              margin: 0 2rem;
              cursor: pointer;
            }

            a:hover {
              color: $primary-color-red;
            }
          }



        }
      }

      .lang-nav {
        float: right;

        ul {
          list-style: none;
          padding: 0.5rem;

          li {
            color: #ffffff;
            font-size: 1.6rem;
            float: left;
            margin: 0.5rem;
            cursor: pointer;
          }

          .list-border {
            padding: 0 1rem 0 0;
            border-right: 1px solid #ffffff;
          }

          li:hover {
            color: $primary-color-red;
          }

        }
      }
    }

    .hero {

      margin: 0 auto;
      max-width: 1200px;
      padding: 30rem 0;

      h1 {
        font-size: 5.8rem;
        margin: 0;
        font-weight: normal;
        line-height: 100%;

        strong {
          font-weight: 900;
        }
      }
      h2 {
        font-size: 3.2rem;
        margin: 3rem 0 15rem 0;
        font-weight: normal;
      }
      a {
        color: #ffffff;
        text-decoration: none;

        span {
          background-image: linear-gradient(45deg, $primary-color-blue 0%, #736FFF 100%);
          border-radius: 4.6rem;
          box-shadow: 0 4px 20px 0 rgba(85, 80, 252, 0.5);
          width: 30rem;
          height: 7rem;
          padding: 2rem 8.7rem;
          color: #ffffff;
          font-size: 2.4rem;
          line-height: 2.9rem;
          text-align: left;
          cursor: pointer;
          font-weight: 900;
        }
      }

    }
  }

  header:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }

  @media only screen and (max-width : 1800px) {
    header {
      .navigation {

        margin: 0 20rem;

        .main-logo {
          margin: 0 2rem 0 0;
          max-width: 40rem;
        }
      }

      .hero {
        margin: 0 20rem;
      }
    }

  }

  @media only screen and (max-width : 1540px) {
    header {
      .navigation {

        .main-logo {
          max-width: 20rem;
          padding-top: 1.5rem;
        }
      }
    }

  }

  @media only screen and (max-width : 1450px) {
    header {
      .navigation {
          margin: 0 10rem;
      }

      .hero {
        margin: 0 10rem;
      }
    }
  }

  @media only screen and (max-width : 1320px) {
    header {
      background: url("./../assets/img/hero.png") no-repeat 110% 100%;

      .navigation {
        margin: 0 2rem 0 4rem;
      }

      .hero {
        margin: 0 2rem 0 4rem;
        padding-top: 15rem;
      }
    }
  }

  @media only screen and (max-width : 1230px) {
    header {
      background: url("./../assets/img/hero.png") no-repeat 150% 30%;
    }
  }

  @media only screen and (max-width : 1024px) {
    header {
      background: url("./../assets/img/hero.png") no-repeat 200% 50%;
    }
  }

  @media only screen and (max-width : 890px) {
    header {
      max-width: 100%;
      background: url("./../assets/img/hero.png") no-repeat 250% 50%;
    }
  }

  @media only screen and (max-width : 850px) {
    header {
      background: url("./../assets/img/hero.png") no-repeat 350% 80%;
      padding-bottom: 5rem;

      .navigation {
        .main-logo {
          max-width: 30em;
        }

        .main-nav {
          display: none;
        }
      }

      .hero {
        padding: 30rem 0 0 0;

        h1 {
          font-size: 4.8rem;
        }
      }

    }
  }

  @media only screen and (max-width : 760px) {
    header {
      background: url("./../assets/img/hero-mobile.png") no-repeat bottom right;

      .navigation {
        .main-logo {
          max-width: 30em;
        }

        .main-nav {
          width: 1px;
        }

        .lang-nav {
          ul {
            color: #000000;

            .list-border {
              border-color: #000000;
            }

            li {
              color: #000000;
              font-size: 3rem;


            }
          }
        }
      }
    }
  }

  @media only screen and (max-width : 550px) {
    header {
      background: url("./../assets/img/hero-mobile.png") no-repeat bottom right;
      padding-bottom: 20rem;

      .navigation {
        .main-logo {
          max-width: 30em;
        }

        .main-nav {
          width: 1px;
        }
      }

      .hero {
        padding: 15rem 0 0 0;

        h1 {
          font-size: 6rem;
          margin: 0 0 3rem;
        }

        h2 {
          font-size: 3rem;
          margin-bottom: 10rem;
        }


      }

    }
  }


</style>
