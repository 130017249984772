<template>
  <footer>
    <div class="triangle-footer-1"></div>
    <div class="triangle-footer-2"></div>
    <section>
      <ul>
        <li class="separator">Nuclicore GmbH</li>
        <li>- TechQuartier -</li>
        <li>Platz der Einheit 2</li>
        <li>60327 Frankfurt am Main</li>
        <li class="separator">{{ $t('imprint.country') }}</li>
<!--        <li>+49 69 3487 1821</li>-->
        <li>kontakt@nuclicore.com</li>
      </ul>
      <ul>
        <li><a href="/imprint">{{ $t('footer.imprintLink') }}</a></li>
        <li class="separator"><a href="https://nuclicore.com/doc/datenschutz.pdf" target="_blank">{{ $t('footer.privacyPolicyLink') }}</a></li>
        <li><a href="https://www.linkedin.com/company/nuclicore" target="_blank">Linkedin</a></li>
      </ul>
    </section>

    <div class="under-footer">
      <p>(c) 2022 Nuclicore GmbH - {{ $t('footer.reservedCopyRights') }}</p>
    </div>
  </footer>

</template>

<script>
  export default {
    name: 'Footer'
  }
</script>

<style lang="scss">
  @import "./../assets/scss/constants/constants";


  footer {
    background: $primary-color-black;
    color: #ffffff;
    padding: 5rem 0 0;
    position: relative;

    .triangle-footer-1 {
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-right-width: 50rem;
      border-left-width: 0;
      border-bottom: 50rem solid $primary-color-blue;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .triangle-footer-2 {
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-top-width: 15rem;
      border-bottom-width: 0;
      border-right: 15rem solid #F96561;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    section {
      display: flex;
      max-width: 1200px;
      margin: 0 auto;

      ul {
        list-style: none;
        flex: 0.3;
        margin: 0;
        padding: 0;
        font-size: 2rem;

        li {
          margin: 1rem 0;

          a {
            color: #ffffff;
            text-decoration: none;
          }

          a:hover {
            color: $primary-color-green;
          }
        }

        .separator {
          margin-bottom: 3rem;
        }
      }
    }

    .under-footer {
      background: #ffffff;
      color: $primary-color-black;
      font-size: 1.6rem;
      padding: 1rem 0;
      margin: 4rem 0 0;

      p {
        max-width: 120rem;
        margin: 0 auto;
        position: relative;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    footer {
      section {
        padding: 0 2rem;
      }
    }
  }

  @media only screen and (max-width: 550px) {
    footer {
      .triangle-footer-1 {
        border-bottom: 20rem solid #5550FC;
        border-right-width: 20rem;
      }
    }
  }

  @media only screen and (max-width: 360px) {
    footer {
      .triangle-footer-1 {
        border-bottom: 5rem solid #5550FC;
        border-right-width: 5rem;
      }
    }
  }
</style>
