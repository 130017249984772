<template>
  <section class="imprint">
    <div class="navigation">
      <img class="main-logo" onclick="window.location.href='/'" src="./../assets/img/logo.png" alt="Nuclicore">
      <nav class="main-nav">
        <ul>
          <li><a href="/#product" >{{ $t('navigation.product') }}</a></li>
          <li><a href="/#technology" >{{ $t('navigation.technology') }}</a></li>
          <li><a href="/#partners" >{{ $t('navigation.partners') }}</a></li>
          <li><a href="/#about" >{{ $t('navigation.about') }}</a></li>
          <li><a href="/#jobs" >{{ $t('navigation.jobs') }}</a></li>
        </ul>
      </nav>
      <nav class="lang-nav">
        <ul>
          <li class="list-border" value="en" @click="changeLocal('en')">EN</li>
          <li value="de" @click="changeLocal('de')">DE</li>
        </ul>
      </nav>
    </div>
    <div class="imprint-content">
      <h1>{{ $t('imprint.title') }}</h1>
      <h2>{{ $t('imprint.subtitle') }}</h2>
      <p><strong>{{ $t('imprint.infoTitle') }}:</strong><br>
        Nuclicore GmbH<br>
        - TechQuartier -<br>
        Platz der Einheit 2<br>
        60327 Frankfurt am Main<br>
        {{ $t('imprint.country') }}</p>
      <p><strong>{{ $t('imprint.registrationTitle') }}:</strong><br>
        Amtsgericht Frankfurt am Main HRB 121489</p>
      <p><strong>{{ $t('imprint.managingDirectors') }}:</strong><br>
        Dr. Eberhard Riesenkampff<br>
      </p>
      <p><strong>{{ $t('imprint.contact') }}:</strong><br>
        {{ $t('imprint.telephone') }}: +49 69 3487 1821<br>
        Email: kontakt@nuclicore.com</p>

      <p><strong>{{ $t('imprint.taxTitle') }}</strong><br>
      {{ $t('imprint.taxContent') }}
      </p>
      <p><strong>{{ $t('imprint.liabilityContentTitle') }}</strong><br>
        {{ $t('imprint.liabilityContentParagraphOne') }}<br>
        {{ $t('imprint.liabilityContentParagraphTwo') }}</p>
      <p><strong>{{ $t('imprint.liabilityLinksTitle') }}</strong><br>
        {{ $t('imprint.liabilityLinksParagraphOne') }}</p>
      <p><strong>{{ $t('imprint.copyrightTitle') }}</strong><br>
        {{ $t('imprint.copyrightParagraphOne') }}<br>
        {{ $t('imprint.copyrightParagraphTwo') }}</p>
    </div>
  </section>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'

  export default  {
    name: 'Imprint',

    mounted() {
      if (localStorage.getItem('lang')) {
        this.$i18n.locale = localStorage.getItem('lang');
      } else {
        let userLang = navigator.language;
        if (userLang === 'de-AT' || userLang === 'de-CH' || userLang === 'de-DE' || userLang === 'de-LI' || userLang === 'de-LU') {
          localStorage.setItem('lang', 'de');
          this.$i18n.locale = 'de';
        } else {
          localStorage.setItem('lang', 'en');
          this.$i18n.locale = 'en';
        }
      }
    },

    methods: {
      changeLocal(lang) {
        console.log(lang);
        this.$i18n.locale = lang;
        localStorage.setItem('lang', lang);
      }
    },

    setup () {

      let fadeInElements = []

      onMounted(() => {
        fadeInElements = Array.from(document.getElementsByClassName('imprint'))
        document.addEventListener('scroll', handleScroll)
        handleScroll();
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', handleScroll)
      })

      const handleScroll = () => {
        for (let i = 0; i < fadeInElements.length; i++) {
          let elem = fadeInElements[i]
          if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
          }
        }
      }

      const isElemVisible = (el) => {
        let rect = el.getBoundingClientRect()
        let elemTop = rect.top + 200 // 200 = buffer
        let elemBottom = rect.bottom
        return elemTop < window.innerHeight && elemBottom >= 0
      }

    }
  }
</script>

<style lang="scss">
  @import "./../assets/scss/constants/constants";

  .imprint {
    background: url("./../assets/img/hero.png") no-repeat right top;
    padding-bottom: 10rem;

    opacity: 0;
    transition: 1s all ease-out;
    transform: scale(1);

    .navigation {

      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      padding: 2rem 0 0;

      .main-logo {
        float: left;
        margin: 0 15rem 0 0;
        width: auto;
      }

      .main-nav {
        float: left;

        ul {
          list-style: none;
          padding: 1rem;

          li {

            float: left;

            a {
              color: $primary-color-black;
              font-size: 1.8rem;
              text-decoration: none;
              margin: 0 2rem;
              cursor: pointer;
            }

            a:hover {
              color: $primary-color-red;
            }
          }
        }
      }

      .lang-nav {
        float: right;

        ul {
          list-style: none;
          padding: 0.5rem;

          li {
            color: #ffffff;
            font-size: 1.6rem;
            float: left;
            margin: 0.5rem;
            cursor: pointer;
          }

          .list-border {
            padding: 0 1rem 0 0;
            border-right: 1px solid #ffffff;
          }

          li:hover {
            color: $primary-color-red;
          }

        }
      }
    }

    .navigation:after {
      visibility: hidden;
      display: block;
      content: "";
      clear: both;
      height: 0;
    }

    .imprint-content {
      margin: 0 auto;
      max-width: 1200px;
      padding-top: 5rem;

      h1 {
        font-size: 4rem;
        margin: 0;
        line-height: 90%;

        strong {
          font-weight: bold;
        }
      }

      p {
        width: 50%;
        font-size: 2rem;
      }
    }

  }

  @media only screen and (max-width : 1800px) {
    .imprint {
      .navigation {
        margin: 0 20rem;

        .main-logo {
          margin: 0 2rem 0 0;
          max-width: 40rem;
        }
      }

      .imprint-content {
        margin: 0 20rem 0 20rem;
      }
    }

  }

  @media only screen and (max-width : 1540px) {
    .imprint {
      .navigation {

        .main-logo {
          max-width: 20rem;
          padding-top: 1.5rem;
        }
      }

    }

  }

  @media only screen and (max-width : 1450px) {
    .imprint {
      .navigation {
        margin: 0 10rem;
      }

      .imprint-content {
        margin: 0 10rem 0 10rem;
      }
    }
  }

  @media only screen and (max-width : 1320px) {
    .imprint {
      background: url("./../assets/img/hero.png") no-repeat 110% 0;

      .navigation {
        margin: 0 2rem 0 4rem;
      }

      .imprint-content {
        margin: 0 2rem 0 4rem;
        padding-top: 5rem;
      }
    }
  }

  @media only screen and (max-width : 1230px) {
    .imprint {
      background: url("./../assets/img/hero.png") no-repeat 150% 0;
    }
  }

  @media only screen and (max-width : 1024px) {
    .imprint {
      background: url("./../assets/img/hero.png") no-repeat 200% 50%;
    }
  }

  @media only screen and (max-width : 890px) {
    .imprint {
      max-width: 100%;
      background: url("./../assets/img/hero.png") no-repeat 250% 50%;
    }
  }

  @media only screen and (max-width : 850px) {
    .imprint {
      background: url("./../assets/img/hero.png") no-repeat 350% 80%;

      .navigation {
        .main-logo {
          max-width: 30em;
        }

        .main-nav {
          display: none;
        }
      }

      .imprint-content {
        padding: 0;
        margin-top: 5rem;

        h1 {
          font-size: 4.8rem;
        }
      }

    }
  }

  @media only screen and (max-width : 760px) {
    .imprint {
      background: url("./../assets/img/hero-mobile.png") no-repeat bottom right;

      .navigation {
        .main-logo {
          max-width: 30em;
        }

        .main-nav {
          width: 1px;
        }

        .lang-nav {
          ul {
            color: #000000;

            .list-border {
              border-color: #000000;
            }

            li {
              color: #000000;
              font-size: 3rem;


            }
          }
        }
      }
    }
  }

  @media only screen and (max-width : 550px) {
    .imprint {
      padding-bottom: 5rem;
      background: url("./../assets/img/hero-mobile.png") no-repeat top right;

      .navigation {
        .main-logo {
          max-width: 30em;
        }

        .main-nav {
          width: 1px;
        }
      }

      .imprint-content {
        padding: 0;

        h1 {
          font-size: 6rem;
          margin: 0 0 3rem;
        }

        h2 {
          font-size: 3rem;
          margin-bottom: 10rem;
        }

        p {
          width: 100%;
        }


      }

    }
  }
</style>
