<template>
  <section class="jobs" id="jobs">
    <img src="./../assets/img/jobs.png" alt="We are hiring" />
    <h1>{{ $t('jobs.title') }}</h1>
    <p>{{ $t('jobs.paragraphOne') }}</p>
    <a href="https://app.nuclicore.com/983e5e1d-f313-48f4-9c2d-273a9c6d5a29"><span>{{ $t('jobs.seeOpenPositions') }}</span></a>
  </section>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
  export default {
    name: 'Jobs',
    setup () {

      let fadeInElements = []

      onMounted(() => {
        fadeInElements = Array.from(document.getElementsByClassName('jobs'))
        document.addEventListener('scroll', handleScroll)
        handleScroll();
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', handleScroll)
      })

      const handleScroll = () => {
        for (let i = 0; i < fadeInElements.length; i++) {
          let elem = fadeInElements[i]
          if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
          }
        }
      }

      const isElemVisible = (el) => {
        let rect = el.getBoundingClientRect()
        let elemTop = rect.top + 200 // 200 = buffer
        let elemBottom = rect.bottom
        return elemTop < window.innerHeight && elemBottom >= 0
      }

    }
  }
</script>

<style lang="scss">
  @import "./../assets/scss/constants/constants";

  .jobs {
    width: 35%;
    padding: 6.6rem 13rem;
    background: $primary-color-black;
    float: right;
    overflow: hidden;
    margin: -10rem 0 0;
    position: relative;
    color: #ffffff;

    opacity: 0;
    transition: 0.3s all ease-out;
    transform: scale(0.8);

    h1 {
      font-size: 5.6rem;
      margin: 1rem 0;
    }

    p {
      font-size: 2rem;
      line-height: 3.2rem;
      margin-bottom: 50px;
    }

    a {
      color: #ffffff;
      text-decoration: none;

      span {
        background-image: linear-gradient(45deg, $primary-color-blue 0%, #736FFF 100%);
        border-radius: 4.6rem;
        box-shadow: 0 4px 20px 0 rgba(85, 80, 252, 0.5);
        width: 30rem;
        height: 7rem;
        padding: 2rem 8.7rem;
        color: #ffffff;
        font-size: 2.4rem;
        line-height: 2.9rem;
        text-align: left;
        cursor: pointer;
        font-weight: 900;
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .jobs {
      width: 100%;
      padding: 0;
      margin-top: -55rem;
      padding-bottom: 5rem;

      img {
        padding: 3rem 3rem 0 3rem;
      }

      h1 {
        padding: 0 3rem;
      }

      p {
        padding: 0 3rem;
        font-size: 3rem;
        margin-bottom: 30px;
      }

      a {
        margin-left: 2rem;
      }

    }
  }

  @media only screen and (max-width: 550px) {
    .jobs {
      margin-top: -75rem;
    }
  }

  @media only screen and (max-width: 410px) {
    .jobs {
      margin-top: -75rem;

      .rectangle-about-1 {
        top: 65rem;
      }

      .rectangle-about-2 {
        top: 72rem;
      }
    }
  }

</style>