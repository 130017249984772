<template>
  <section class="about" id="about">
    <div class="rectangle-about-1"></div>
    <div class="rectangle-about-2"></div>
    <div class="about-content">
      <img src="./../assets/img/black-logo.png" alt="Nuclicore" />
      <h1>{{ $t('about.title') }}</h1>
      <p>{{ $t('about.paragraphOne') }}</p>
      <p>{{ $t('about.paragraphTwo') }}</p>
    </div>
  </section>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
  export default {
    name: 'About',
    setup () {

      let fadeInElements = []

      onMounted(() => {
        fadeInElements = Array.from(document.getElementsByClassName('about'))
        document.addEventListener('scroll', handleScroll)
        handleScroll();
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', handleScroll)
      })

      const handleScroll = () => {
        for (let i = 0; i < fadeInElements.length; i++) {
          let elem = fadeInElements[i]
          if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
          }
        }
      }

      const isElemVisible = (el) => {
        let rect = el.getBoundingClientRect()
        let elemTop = rect.top + 200 // 200 = buffer
        let elemBottom = rect.bottom
        return elemTop < window.innerHeight && elemBottom >= 0
      }

    }
  }
</script>

<style lang="scss">
  @import "./../assets/scss/constants/constants";

  .about {
    height: 105rem;
    background: url("./../assets/img/skyline.png") no-repeat left bottom;
    position: relative;

    opacity: 0;
    transition: 0.3s all ease-out;
    transform: scale(0.8);

    .rectangle-about-1 {
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-right-width: 14.9rem;
      border-left-width: 0;
      border-bottom: 15rem solid $primary-color-black;
      position: absolute;
      top: 30rem;
      left: 0;
    }

    .rectangle-about-2 {
      position: absolute;
      top: 45rem;
      left: 7.08rem;
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-left-width: 78px;
      border-right-width: 0px;
      border-top: 74px solid $primary-color-blue;
    }

    .about-content {
      max-width: 1200px;
      margin: 0 auto;

      h1 {
        font-size: 5.6rem;
        width: 50%;
        margin: 1rem 0;
      }

      p {
        font-size: 2rem;
        line-height: 3.2rem;
        width: 50%;
      }
    }
  }

  @media only screen and (max-width : 1320px) {
    .about {
      .about-content {
        margin: 0 4rem;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .about {
      height: 125rem;


      .about-content {
        margin: 0 4rem;
      }
    }

  }

  @media only screen and (max-width: 1024px) {
    .about {
      height: 140rem;

      .rectangle-about-2 {
        border-left-width: 54px;
        border-top: 54px solid #5550FC;
      }
    }
  }

  @media only screen and (max-width: 760px) {
    .about {
      height: 140rem;
      background: url("./../assets/img/skyline.png") no-repeat center center;
      background-size: contain;

      .about-content {
        max-width: 100%;

        p {
          width: 100%;
          font-size: 3rem;
        }
      }

      .rectangle-about-1 {
        top: 50rem;
      }

      .rectangle-about-2 {
        top: 57rem;
        left: 7rem;
      }
    }
  }

  @media only screen and (max-width: 550px) {
    .about {
      height: 165rem;

      .rectangle-about-1 {
        top: 60rem;
      }

      .rectangle-about-2 {
        top: 67rem;
      }
    }
  }
  
</style>