<template>
  <section id="demo" class="demo">
    <h1>{{ $t('demo.title') }}</h1>
    <h3>{{ $t('demo.subtitle') }}</h3>
    <div class="demo-content">
      <form class="demo-form">
        <input type="text" v-model.trim="demoName" :placeholder="$t('demo.name')" />
        <input type="email" v-model.trim="demoEmail" :placeholder="$t('demo.email')" />
        <p class="toc" v-html="$t('demo.toc')"></p>
        <p class="error-message" v-if="validationErrorMessage">{{ $t('demo.validationError') }}</p>
        <p class="error-message" v-if="errorMessage">{{ $t('demo.error') }}</p>
        <p class="success-message" v-if="successMessage">{{ $t('demo.success') }}</p>
        <span @click="sendEmail()">{{ $t('demo.send') }}</span>
      </form>
    </div>
  </section>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'

import axios from 'axios'
  export default {
    name: 'Demo',
    data() {
      return {
        demoName: '',
        demoEmail: '',
        successMessage: false,
        errorMessage: false,
        validationErrorMessage: false,
      }
    },
    setup () {

      let fadeInElements = []

      onMounted(() => {
        fadeInElements = Array.from(document.getElementsByClassName('demo'))
        document.addEventListener('scroll', handleScroll)
        handleScroll();
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', handleScroll)
      })

      const handleScroll = () => {
        for (let i = 0; i < fadeInElements.length; i++) {
          let elem = fadeInElements[i]
          if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
          }
        }
      }

      const isElemVisible = (el) => {
        let rect = el.getBoundingClientRect()
        let elemTop = rect.top + 200 // 200 = buffer
        let elemBottom = rect.bottom
        return elemTop < window.innerHeight && elemBottom >= 0
      }

    },
    methods: {
      validateEmail(email) {
        const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
      },

      validate() {
        if (this.demoEmail.length < 6 || this.demoName.length < 2) {
          this.validationErrorMessage = true;
          return false;
        }

        if (!this.validateEmail(this.demoEmail)) {
          this.validationErrorMessage = true;
          return false;
        }

        return true;
      },

      sendEmail() {

        if (!this.validate()) {
          return false;
        }

        const that = this;
        axios.post('https://email.platform.nuclicore.com',
            'email=' + this.demoEmail + '&name=' + this.demoName, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              }})
            .then(function (response) {
              if (response.data) {
                that.successMessage = true;
                that.errorMessage = false;
                that.validationErrorMessage = false;
              } else {
                that.errorMessage = true;
                that.successMessage = false;
                that.validationErrorMessage = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              this.errorMessage = true;
              this.successMessage = false;
              that.validationErrorMessage = false;
            });
      }
    }
  }
</script>

<style lang="scss">
  @import "./../assets/scss/constants/constants";


  .demo {
    max-width: 120rem;
    margin: 40rem auto 0 auto;

    opacity: 0;
    transition: 0.3s all ease-out;
    transform: scale(0.8);

    h1 {
      font-size: 5.6rem;
      margin: 0 0 1rem 0;
      padding: 0 0 0 12rem;
    }

    h3 {
      font-size: 2.4rem;
      font-weight: lighter;
      padding: 0 0 0 12rem;
    }

    .demo-content {
      background: url('./../assets/img/contact-illustration.png') no-repeat bottom right;
      padding: 1rem 0;

      .demo-form {
        background: #ffffff;
        width: 60%;
        margin: 12rem 0 0;
        text-align: center;
        padding: 7rem 0;
        box-shadow: 0 6px 50px 0 rgba(0, 0, 0, 0.1);

        input {
          border: 1px solid #1A1618;
          border-radius: 35px;
          padding: 2rem 2rem;
          display: block;
          margin: 0 auto 2rem auto;
          position: relative;
          width: 60%;
          font-size: 2rem;
        }

        .toc {
          width: 50%;
          margin: 0 auto 2rem auto;
          position: relative;
          font-size: 1.2rem;
          color: #aaaaaa;
        }

        span {
          border-radius: 35px;
          display: block;
          margin: 0 auto 2rem auto;
          position: relative;
          color: #ffffff;
          background: $primary-color-blue;
          font-size: 2.4rem;
          padding: 2rem;
          width: 60%;
          border: none;
          cursor: pointer;
          font-weight: 900;
        }

        input:focus {
          outline: none;
        }

        .success-message {
          color: $primary-color-green;
          font-size: 2rem;
          font-weight: bold;
        }

        .error-message {
          color: $primary-color-red;
          font-size: 2rem;
          font-weight: bold;
        }

        a {
          color: $primary-color-blue;
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (max-width: 760px) {
    .demo {
      margin: -10rem auto 0 auto;

      h1 {
        padding: 0;
        text-align: center;
        font-size: 5rem;
      }

      h3 {
        padding-left: 0;
        text-align: center;
        font-size: 2rem;
      }

      .demo-content {
        .demo-form {
          width: 96%;
          margin: 2rem 2rem;
        }
      }


    }
  }

  @media only screen and (max-width: 550px) {
    .demo {
      margin-top: -30rem;

      .demo-content {
        .demo-form {
          margin: 2rem 0;
        }
      }

      h3 {
        font-size: 3rem;
      }
    }
  }



</style>
