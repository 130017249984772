<template>
  <section class="technology" id="technology">
    <div class="triangle"></div>
    <div class="technology-content">
      <img src="./../assets/img/tech.svg" alt="Technology" class="tech-img" />
      <div class="technology-text">
        <h1>{{ $t('technology.title') }}</h1>
        <p>{{ $t('technology.paragraphOne') }}</p>
        <p>{{ $t('technology.paragraphTwo') }}</p>
      </div>
    </div>
  </section>
</template>

<script>

import { onMounted, onUnmounted } from 'vue'
  export default {
    name: 'Technology',
    setup () {

      let fadeInElements = []

      onMounted(() => {
        fadeInElements = Array.from(document.getElementsByClassName('technology'))
        document.addEventListener('scroll', handleScroll)
        handleScroll();
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', handleScroll)
      })

      const handleScroll = () => {
        for (let i = 0; i < fadeInElements.length; i++) {
          let elem = fadeInElements[i]
          if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
          }
        }
      }

      const isElemVisible = (el) => {
        let rect = el.getBoundingClientRect()
        let elemTop = rect.top + 200 // 200 = buffer
        let elemBottom = rect.bottom
        return elemTop < window.innerHeight && elemBottom >= 0
      }

    }
  }
</script>

<style lang="scss">
  @import "./../assets/scss/constants/constants";

  .technology {
    background: $primary-color-blue url('./../assets/img/tech.png') no-repeat bottom left;
    height: 140rem;
    max-width: 100%;
    position: relative;
    margin-bottom: 20rem;

    opacity: 0;
    transition: 0.3s all ease-out;
    transform: scale(0.8);

    .triangle {
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-bottom-width: 10.8rem;
      border-top-width: 70.3rem;
      border-left: 80rem solid #514CF0;
      position: absolute;
      left: 0;
      top: 0;
    }

    .technology-content {
      margin: 0 auto;
      position: relative;
      display: flex;

      img {
        width: 50%;
      }

      .technology-text {
        color: #ffffff;
        flex: 1;
        margin: 5rem 5rem 5rem 0;
      }

      h1 {
        font-size: 5.7rem;
        margin-top: 0;
      }

      p {
        font-size: 2rem;
      }
    }
  }

  @media only screen and (max-width : 1800px) {
    .technology {
      .technology-content {
        margin: 0 20em;
      }
    }
  }

  @media only screen and (max-width : 1600px) {
    .technology {
      .technology-content {
        margin: 0 10rem;
      }
    }
  }

  @media only screen and (max-width : 1320px) {
    .technology {
      .technology-content {
        margin: 0 4rem;
      }
    }
  }

  @media only screen and (max-width : 1200px) {
    .technology {
      .triangle {
        border-top-width: 55.3rem;
      }
    }
  }

  @media only screen and (max-width : 1024px) {
    .technology {
      .triangle {
        border-bottom-width: 33.8rem;
        border-left: 180rem solid #514CF0;
        border-top-width: 45.3rem;
      }
    }
  }

  @media only screen and (max-width: 760px) {
    .technology {
      height: 290rem;
      margin-bottom: 5rem;

      .technology-content {
        display: block;

        .technology-text {
          margin: 5rem 0;
        }

        img {
          width: 100%;
          horiz-align: center;
        }

        p {
          font-size: 3rem;
        }
      }

      .triangle {
        border-bottom-width: 23.8rem;
        border-top-width: 111.3rem;
        border-left: 160rem solid #514CF0;
      }

    }
  }

  @media only screen and (max-width: 500px) {
    .technology {
      height: 200rem;
    }
  }

  @media only screen and (max-width: 400px) {
    .technology {
      height: 200rem;
    }
  }
</style>