<template>
  <Header></Header>
  <Product></Product>
  <Technology></Technology>
  <Partners></Partners>
  <About></About>
  <Jobs></Jobs>
  <Demo></Demo>
  <Footer></Footer>
</template>
<script>
import Header from "@/components/Header";
import Product from "@/components/Product";
import Technology from "@/components/Technology";
import About from "@/components/About";
import Jobs from "@/components/Jobs";
import Demo from "@/components/Demo";
import Partners from "@/components/Partners";
import Footer from "@/components/Footer";
export default {
  components: {Footer, Partners, Demo, Jobs, About, Technology, Product, Header}
}
</script>

<style lang="scss">
  @import "./../assets/scss/mixins/main";

</style>