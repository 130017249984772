<template>
  <section class="product" id="product">
    <div class="product-content">
      <h1>{{ $t('product.title') }}</h1>
      <p>{{ $t('product.paragraphOne') }}</p>
      <p>{{ $t('product.paragraphTwo') }}</p>
      <p>{{ $t('product.paragraphThree') }}</p>
    </div>
    <img src="./../assets/img/product.svg" class="product-img" alt="Nuclicore"/>
  </section>
</template>

<script>
  import { onMounted, onUnmounted } from 'vue'

  export default {
    name: 'Product',
    setup () {

      let fadeInElements = []

      onMounted(() => {
        fadeInElements = Array.from(document.getElementsByClassName('product'))
        document.addEventListener('scroll', handleScroll)
        handleScroll();
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', handleScroll)
      })

      const handleScroll = () => {
        for (let i = 0; i < fadeInElements.length; i++) {
          let elem = fadeInElements[i]
          if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
          }
        }
      }

      const isElemVisible = (el) => {
        let rect = el.getBoundingClientRect()
        let elemTop = rect.top + 200 // 200 = buffer
        let elemBottom = rect.bottom
        return elemTop < window.innerHeight && elemBottom >= 0
      }

    }
  }
</script>

<style lang="scss">
  @import "./../assets/scss/constants/constants";

  .product {

    display: flex;
    max-width: 1200px;
    margin: 0 auto 10rem auto;

    opacity: 0;
    transition: 0.3s all ease-out;
    transform: scale(0.8);

    .product-content {
      flex: 0 0 50%;

      h1 {
        font-size: 8rem;
        margin: 0;
      }

      p {
        margin: 2rem 0;
        font-size: 2rem;
      }
    }

    .product-img {
      width: 75%;
    }
  }

  @media only screen and (max-width : 1800px) {
    .product {
      margin: 0 20rem 10rem 20rem;
    }
  }

  @media only screen and (max-width : 1540px) {
    .product {
      display: block;
      .product-img {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1450px) {
    .product {
      margin: 0 10rem 10rem 10rem;
    }
  }

  @media only screen and (max-width : 1320px) {
    .product {
      margin: 0 4rem 10rem 4rem;
    }
  }

  @media only screen and (max-width: 850px) {
    .product {
      margin: 10rem 4rem 10rem 4rem;
    }
  }

  @media only screen and (max-width: 760px) {
    .product {
      .product-content {
        p {
          font-size: 3rem;
        }
      }

    }
  }

</style>