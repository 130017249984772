<template>
  <Imprint></Imprint>
  <Footer></Footer>
</template>
<script>
import Imprint from "@/components/Imprint";
import Footer from "@/components/Footer";
export default {
  components: {Footer, Imprint}
}
</script>

<style lang="scss">
  @import "./../assets/scss/mixins/main";

</style>